
// ### Event Codes Table
// | Code   | Name                                 | Parameter 1       | Parameter 2              | Parameter 3           | Description                                                                                               |
// |:-------|:-------------------------------------|:------------------|:-------------------------|:----------------------|:----------------------------------------------------------------------------------------------------------|
// | **12** | AutoPing Timeout                     | Target IP Address | # fails before action    | --                    | IP Address to Ping. Failure to ping creates trigger.                                                      |
// | **13** | Inlet Line Max Current Threshold     | Inlet Line        | Amperage Threshold       | # secs past threshold | Applies to 3 phase PDUs with line measurements. Amperage threshold in amps                                |
// | **14** | Inlet Line Min Current Threshold     | Inlet Line        | Amperage Threshold       | # secs past threshold | Applies to 3 phase PDUs with line measurements. Amperage threshold in amps                                |
// | **15** | Inlet Max Current Threshold          | Inlet ID          | Amperage Threshold       | # secs past threshold | Applies to single phase PDUs. Amperage threshold in amps                                                  |
// | **16** | Inlet Min Current Threshold          | Inlet ID          | Amperage Threshold       | # secs past threshold | Applies to single phase PDUs. Amperage threshold in amps                                                  |
// | **17** | Inlet Max Voltage Threshold          | Inlet ID          | Voltage Threshold        | # secs past threshold | Applies to single phase PDUs. Voltage threshold in volts                                                  |
// | **18** | Inlet Min Voltage Threshold          | Inlet ID          | Voltage Threshold        | # secs past threshold | Applies to single phase PDUs. Voltage threshold in volts                                                  |
// | **19** | Inlet Max Line Frequency Threshold   | Inlet ID          | Line Frequency Threshold | # secs past threshold | Applies to single phase PDUs. Line frequency threshold in hertz                                           |
// | **20** | Inlet Max Line Frequency Threshold   | Inlet ID          | Line Frequency Threshold | # secs past threshold | Applies to single phase PDUs. Line frequency threshold in hertz                                           |
// | **21** | Inlet Min Power Factor               | Inlet ID          | Power Factor Threshold   | # secs past threshold | Power Factor Threshold as a ratio                                                                         |
// | **22** | Inlet Active Energy Accumulation Max | Inlet ID          | kWh Threshold            | --                    | --                                                                                                        |
// | **24** | Inlet Min Active Power Threshold     | Inlet ID          | Active Power Threshold   | # secs past threshold | Active Power Threshold in watts                                                                           |
// | **23** | Inlet Max Active Power Threshold     | Inlet ID          | Active Power Threshold   | # secs past threshold | Active Power Threshold in watts                                                                           |
// | **25** | Inlet Max Apparent Power Threshold   | Inlet ID          | Apparent Power Threshold | # secs past threshold | Apparent Power Threshold in volt-amperes (VA)                                                             |
// | **26** | Inlet Min Apparent Power Threshold   | Inlet ID          | Apparent Power Threshold | # secs past threshold | Apparent Power Threshold in volt-amperes (VA)                                                             |
// | **29** | Inlet 3 Phase Imbalance              | Inlet ID          | % Imbalance Threshold    | # secs past threshold | --                                                                                                        |
// | **30** | Bank Circuit Breaker Trip            | Bank ID           | --                       | --                    | --                                                                                                        |
// | **31** | Bank Max Current Threshold           | Bank ID           | Amperage Threshold       | # secs past threshold | Amperage threshold in amps                                                                                |
// | **32** | Bank Min Current Threshold           | Bank ID           | Amperage Threshold       | # secs past threshold | Amperage threshold in amps                                                                                |
// | **33** | Bank Max Voltage Threshold           | Bank ID           | Voltage Threshold        | # secs past threshold | Voltage threshold in volts                                                                                |
// | **34** | Bank Min Voltage Threshold           | Bank ID           | Voltage Threshold        | # secs past threshold | Voltage threshold in volts                                                                                |
// | **35** | Bank Max Line Frequency Threshold    | Bank ID           | Line Frequency Threshold | # secs past threshold | Line Frequency threshold in hertz                                                                         |
// | **36** | Bank Min Line Frequency Threshold    | Bank ID           | Line Frequency Threshold | # secs past threshold | Line Frequency threshold in hertz                                                                         |
// | **37** | Bank Min Power Factor                | Bank ID           | Power Factor Threshold   | # secs past threshold |                                                                                                           |
// | **38** | Bank Active Energy Accumulation Max  | Bank ID           | kWh Threshold            | --                    |                                                                                                           |
// | **39** | Bank Max Active Power Threshold      | Bank ID           | Active Power Threshold   | # secs past threshold | Active Power Threshold in watts                                                                           |
// | **40** | Bank Min Active Power Threshold      | Bank ID           | Active Power Threshold   | # secs past threshold | Active Power Threshold in watts                                                                           |
// | **41** | Bank Max Apparent Power Threshold    | Bank ID           | Apparent Power Threshold | # secs past threshold | Apparent Power Threshold in volt-amperes (VA)                                                             |
// | **42** | Bank Min Apparent Power Threshold    | Bank ID           | Apparent Power Threshold | # secs past threshold | Apparent Power Threshold in volt-amperes (VA)                                                             |
// | **45** | Outlet Max Current Threshold         | Outlet ID         | Amperage Threshold       | # secs past threshold | Applies to PDUs with Outlet Current Measurements. Amperage threshold in amps                              |
// | **46** | Outlet Min Current Threshold         | Outlet ID         | Amperage Threshold       | # secs past threshold | Applies to PDUs with Outlet Current Measurements. Amperage threshold in amps                              |
// | **49** | Temperature Max Threshold            | Sensor ID         | Temperature Threshold    | # secs past threshold | Requires attached temperature/humidity sensor. Temperature threshold in celsius                           |
// | **50** | Temperature Min Threshold            | Sensor ID         | Temperature Threshold    | # secs past threshold | Requires attached temperature/humidity sensor. Temperature threshold in celsius                           |
// | **51** | Humidity Max Threshold               | Sensor ID         | Humidity Threshold       | # secs past threshold | Requires attached temperature/humidity sensor. Humidity threshold as relative humidity %                  |
// | **52** | Humidity Min Threshold               | Sensor ID         | Humidity Threshold       | # secs past threshold | Requires attached temperature/humidity sensor. Humidity threshold as relative humidity %                  |
// | **53** | Scheduled Daily Time                 | Time to trigger   | --                       | --                    | Time of day in military time. example: 13:01                                                              |
// | **54** | Scheduled Time Interval              | --                | --                       | --                    | Triggers every X seconds forever                                                                          |
// | **55** | ATS Switched to Secondary            | --                | --                       | --                    | Applies to ATS PDUs. Triggers when Primary ATS Inlet has lost power and switches to Secondary             |
// | **56** | ATS Switched to Primary              | --                | --                       | --                    | Applies to ATS PDUs. Triggers when Primary ATS Inlet has recovered and switches from Secondary to Primary |
// | **57** | Secondary Power Source Lost          | --                | --                       | --                    | Applies to ATS PDUs. Triggers when Secondary ATS Inlet has lost power.                                    |
// | **58** | Secondary Power Source Restored      | --                | --                       | --                    | Applies to ATS PDUs. Triggers when Secondary ATS Inlet has recovered power.                               |
// | **59** | System Power Recovery Event          | Bank ID           | --                       | --                    | Fires when the given Bank has booted up after having no power. Will fire with circuit breaker trip for supported devices. For single inlet devices, choose any bank. For Dual circuit devices, Banks are linked with inlets.                               |
// | **60** | Monthly Scheduled Event              | Day of Month                | Hour Of Day                       | Minute of Hour                    | Day of month as a number between 1 and 28, hour of day as 0-23, minute as 0-59 |
// | **61** | Weekly Scheduled Event               | Day of Week                | Hour of Day                       | Minute of hour                    | Day of week as 0-6, hour of day as 0-23, minute at 0-59 |
// | **62** | Digital Input State High             | Sensor Port (A or B)       | Digital Input Index (1-6)         | # secs past threshold             | Requires SPA-0140 Smart IO Hub. Triggers an event when a hub's digital input senses high for # secs past threshold |
// | **63** | Digital Input State Low              | Sensor Port (A or B)       | Digital Input Index (1-6)         | # secs past threshold             | Requires SPA-0140 Smart IO Hub. Triggers an event when a hub's digital input senses low for # secs past threshold |
// | **64** | User Switch Triggered                | Sensor Port (A or B)       | State triggered to                | --                                | Requires SPA-0140 Smart IO Hub. If state is triggered OFF, then when user switch is turned off, event is triggered |
// | **65** | Sensor Connected/Disconnected        | Sensor Port (A or B)       | Connect/Disconnect                | --                                |  |

function ValidateIPaddress(ipaddress) {  
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
    return (true)  
  }  
  return (false)  
} 

const eventRefs =  [{
  code: 11,
  name: "Network Device Detection",
  helpSections: [{
    title: "Network Device Detection",
    desc:"This feature scans the network for a specific IP address. When that IP becomes online (checked via Ping), actions can be executed."
  }, {
    title: "# Successful Pings Before Action",
    desc: "Defines the consecutive number of pings that have to be successful before executing actions."
  }, {
    title: "Network Detection Timeout",
    desc: "Network Detection Timeout global setting can be changed in System -> Autoping with the Ping Timeout setting. This will change the amount of time the SynLink device waits for a ping response."
  }, {
    title: "Network Detection Interval",
    desc: "Network Detection Interval global setting can be changed in System -> Autoping with the Ping Interval setting. This will change the frequency that the SynLink device pings a target host."
  }],
  params: [{
    label: "IP Address to Scan",
    unit: "",
    input: "text",
    placeholder: "eg. 192.168.1.101",
    indicatorThreshold: true // means this is the value that matters when showing the user there's an issue
  }, {
    label: "# Successful Pings Before Action",
    unit: "",
    input: "number",
    placeholder: "eg. 3",
    min: "1",
    max: "100",
    validation: value => ((value >= 1) && (value <=100)) ? "" : "Must be between 1 and 100"
  }],
  triggeredDescription: "{{param1}} has been pinged {{param2}} times",
  notTriggeredDescription: "{{param1}} has not had {{param2}} pings"
},{
  code: 12,
  name: "AutoPing Timeout",
  helpSections: [{
    title: "# Fails Before Action",
    desc: "AutoPing failures do not count towards # Fails unless it has established one successful ping first. # Fails have to be consecutive to increment. Successful pings restart the counter"
  }, {
    title: "AutoPing Timeout",
    desc: "Ping Timeout global setting can be changed in System -> Autoping. This will change the amount of time the SynLink device waits for a ping response."
  }, {
    title: "AutoPing Interval",
    desc: "Ping Interval global setting can be changed in System -> Autoping. This will change the frequency that the SynLink device pings a target host."
  }],
  params: [{
    label: "IP Address to Ping",
    unit: "",
    input: "text",
    placeholder: "eg. 192.168.1.101",
    indicatorThreshold: true, // means this is the value that matters when showing the user there's an issue
    validation: value => ValidateIPaddress(value) ? "" : "Invalid IP Address"
  }, {
    label: "# Fails Before Action",
    unit: "",
    input: "number",
    placeholder: "eg. 3",
    min: "1",
    max: "100",
    validation: value => ((value >= 1) && (value <=100)) ? "" : "Must be between 1 and 100"
  }],
  triggeredDescription: "{{param1}} has exceeded {{param2}} failed consecutive pings",
  notTriggeredDescription: "{{param1}} has not had {{param2}} failed consecutive pings"
}, {
  code: 13,
  name: "Inlet Line Max Current Threshold",
  params: [{
    label: "Line",
    unit: "",
    input: "select",
    options: [{
      value: "L1",
      label: "L1"
    },{
      value: "L2",
      label: "L2"
    },{
      value: "L3",
      label: "L3"
    }]
  },{
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 16 amps",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Line {{param1}} has exceeded {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Line {{param1}} has not exceeded {{param2}} amps for {{param3}} seconds"

}, {
  code: 14,
  name: "Inlet Line Min Current Threshold",
  params: [{
    label: "Line",
    unit: "",
    input: "select",
    options: [{
      value: "L1",
      label: "L1"
    },{
      value: "L2",
      label: "L2"
    },{
      value: "L3",
      label: "L3"
    }]
  },{
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 16 amps",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Line {{param1}} has fallen below {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Line {{param1}} has not fallen below {{param2}} amps for {{param3}} seconds"
}, {
  code: 15,
  name: "Inlet Max Current Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 16 amps",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} amps for {{param3}} seconds"
}, {
  code: 16,
  name: "Inlet Min Current Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Current RMS Threshold",
    unit:"Amps",
    input: "number",
    placeholder: "eg. 16 amps",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} amps for {{param3}} seconds"
}, {
  code: 17,
  name: "Inlet Max Voltage RMS Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Voltage RMS Threshold",
    unit: "VAC",
    input: "number",
    placeholder: "eg. 128",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "1000"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} volts for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} volts for {{param3}} seconds"
}, {
  code: 18,
  name: "Inlet Min Voltage RMS Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Voltage RMS Threshold",
    unit: "VAC",
    input: "number",
    placeholder: "eg. 110",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "1000"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} volts for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} volts for {{param3}} seconds"
}, {
  code: 19,
  name: "Inlet Max Line Frequency Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Line Frequency Threshold",
    unit: "Hz",
    input: "number",
    placeholder: "eg. 70",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} Hz for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} Hz for {{param3}} seconds"
}, {
  code: 20,
  name: "Inlet Min Line Frequency Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Line Frequency Threshold",
    unit: "Hz",
    input: "number",
    placeholder: "eg. 50",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} Hz for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} Hz for {{param3}} seconds"
}, {
  code: 21,
  name: "Inlet Min Power Factor",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Power Factor Threshold",
    unit: "",
    input: "number",
    placeholder: "eg. 80",
    step: "0.01",
    indicatorThreshold: true, // TODO min max?????
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} power factor for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} power factor for {{param3}} seconds"
}, {
  code: 22,
  name: "Inlet Active Energy Accumulation Max",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label:"kWh Threshold",
    unit: "kWh",
    input: "number",
    placeholder: "eg. 104",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} kWh",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} kWh"
}, {
  code: 23,
  name: "Inlet Max Active Power Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Active Power Threshold",
    unit: "Watts",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} watts for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} watts for {{param3}} seconds"
}, {
  code: 24,
  name: "Inlet Min Active Power Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Active Power Threshold",
    unit: "Watts",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} watts for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} watts for {{param3}} seconds"
}, {
  code: 25,
  name: "Inlet Max Apparent Power Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Apparent Power Threshold",
    unit: "volt-amperes (VA)",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has exceeded {{param2}} VA for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not exceeded {{param2}} VA for {{param3}} seconds"
}, {
  code: 26,
  name: "Inlet Min Apparent Power Threshold",
  params: [{
    label: "Inlet Plug",
    unit: "",
    input: "select"
  },{
    label: "Apparent Power Threshold",
    unit: "volt-amperes (VA)",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Inlet {{param1}} has fallen below {{param2}} VA for {{param3}} seconds",
  notTriggeredDescription: "Inlet {{param1}} has not fallen below {{param2}} VA for {{param3}} seconds"
}, 
// {
//   code: 27,
//   name: "Inlet Max Reactive Power Threshold",
//   params: [{
//     label: "Inlet Plug",
//     unit: "",
//     input: "select"
//   },{
//     label: "Reactive Power Threshold",
//     unit: "volt-amperes reactive (VAR)",
//     input: "number",
//     placeholder: "",
//     step: "0.01",
//     indicatorThreshold: true,
//     min: "0",
//     max: "2147483646"
//   }, {
//     label: "Max Time Over Threshold Before Action",
//     unit:  "Seconds",
//     input: "number",
//     placeholder: "eg. 3",
//     min: "3",
//     max: "86400"
//   }],
// }, 
// {
//   code: 28,
//   name: "Inlet Min Reactive Power Threshold",
//   params: [{
//     label: "Inlet Plug",
//     unit: "",
//     input: "select"
//   },{
//     label: "Reactive Power Threshold",
//     unit: "volt-amperes reactive (VAR)",
//     input: "number",
//     placeholder: "",
//     step: "0.01",
//     indicatorThreshold: true,
//     min: "0",
//     max: "2147483646"
//   }, {
//     label: "Max Time Over Threshold Before Action",
//     unit: "Seconds",
//     input: "number",
//     placeholder: "eg. 3",
//     min: "3",
//     max: "86400"
//   }],
// }, 
// {
//   code: 29,
//   name: "Inlet 3 Phase Imbalance",
//   params: [{
//     label: "Inlet Plug",
//     unit: "",
//     input: "select"
//   }, {
//     label: "% Imbalance Threshold",
//     unit: "% Phase Imbalance",
//     input: "number",
//     placeholder: "",
//     step: "0.01",
//     indicatorThreshold: true,
//     min: "0",
//     max: "100"
//   }, {
//     label: "Max Time Over Threshold Before Action",
//     unit: "Seconds",
//     input: "number",
//     placeholder: "eg. 3",
//     min: "3",
//     max: "86400"
//   }],
// }, 
{
  code: 30,
  name: "Bank Circuit Breaker Trip",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
    indicatorThreshold: true,
  }],
  triggeredDescription: "Bank {{param1}} circuit breaker has tripped",
  notTriggeredDescription: "Bank {{param1}} circuit breaker has not tripped"
}, {
  code: 31,
  name: "Bank Max Current Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 16",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} amps for {{param3}} seconds"
}, {
  code: 32,
  name: "Bank Min Current Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} amps for {{param3}} seconds"
}, {
  code: 33,
  name: "Bank Max Voltage RMS Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Voltage RMS Threshold",
    unit: "VAC",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "1000"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} volts for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} volts for {{param3}} seconds"
}, {
  code: 34,
  name: "Bank Min Voltage RMS Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Voltage RMS Threshold",
    unit: "VAC",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "1000"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} volts for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} volts for {{param3}} seconds"
}, {
  code: 35,
  name: "Bank Max Line Frequency Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Line Frequency Threshold",
    unit: "Hz",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} Hz for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} Hz for {{param3}} seconds"
}, {
  code: 36,
  name: "Bank Min Line Frequency Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Line Frequency Threshold",
    unit: "Hz",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action" ,
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} Hz for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} Hz for {{param3}} seconds"
}, {
  code: 37,
  name: "Bank Min Power Factor",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Power Factor Threshold",
    unit: "",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true, // TODO min max power factor?
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} power factor for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} power factor for {{param3}} seconds"
}, {
  code: 38,
  name: "Bank Active Energy Accumulation Max",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "kWh Threshold",
    unit: "kWh",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} kWh",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} kWh"
}, {
  code: 39,
  name: "Bank Max Active Power Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Active Power Threshold",
    unit: "Watts",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} watts for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} watts for {{param3}} seconds"
}, {
  code: 40,
  name: "Bank Min Active Power Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Active Power Threshold",
    unit: "Watts",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} watts for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} watts for {{param3}} seconds"
}, {
  code: 41,
  name: "Bank Max Apparent Power Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Apparent Power Threshold",
    unit: "volt-amperes (VA)",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has exceeded {{param2}} VA for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not exceeded {{param2}} VA for {{param3}} seconds"
}, {
  code: 42,
  name: "Bank Min Apparent Power Threshold",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
  }, {
    label: "Apparent Power Threshold",
    unit: "volt-amperes (VA)",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "2147483646"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Bank {{param1}} has fallen below {{param2}} VA for {{param3}} seconds",
  notTriggeredDescription: "Bank {{param1}} has not fallen below {{param2}} VA for {{param3}} seconds"
}, 
// {
//   code: 43,
//   name: "Bank Max Reactive Power Threshold",
//   params: [{
//     label: "Bank",
//     unit: "",
//     input: "select",
//   }, {
//     label: "Reactive Power Threshold",
//     unit: "volt-amperes reactive (VAR)",
//     input: "number",
//     placeholder: "",
//     step: "0.01",
//     indicatorThreshold: true,
//     min: "0",
//     max: "2147483646"
//   }, {
//     label: "Max Time Over Threshold Before Action",
//     unit: "Seconds",
//     input: "number",
//     placeholder: "eg. 3",
//     min: "3",
//     max: "86400"
//   }],
// }, 
// {
//   code: 44,
//   name: "Bank Min Reactive Power Threshold",
//   params: [{
//     label: "Bank",
//     unit: "",
//     input: "select",
//   }, {
//     label: "Reactive Power Threshold",
//     unit: "volt-amperes reactive (VAR)",
//     input: "number",
//     placeholder: "",
//     step: "0.01",
//     indicatorThreshold: true,
//     min: "0",
//     max: "2147483646"
//   }, {
//     label: "Max Time Over Threshold Before Action",
//     unit: "Seconds",
//     input: "number",
//     placeholder: "eg. 3",
//     min: "3",
//     max: "86400"
//   }],
// }, 
{
  code: 45,
  name: "Outlet Max Current Threshold",
  params: [{
    label: "Outlet",
    unit: "",
    input: "select",
  }, {
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 10",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Outlet {{param1}} has exceeded {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Outlet {{param1}} has not exceeded {{param2}} amps for {{param3}} seconds"
}, {
  code: 46,
  name: "Outlet Min Current Threshold",
  params: [{
    label: "Outlet",
    unit: "",
    input: "select"
  }, {
    label: "Current RMS Threshold",
    unit: "Amps",
    input: "number",
    placeholder: "eg. 10",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100"
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Outlet {{param1}} has fallen below {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Outlet {{param1}} has not fallen below {{param2}} amps for {{param3}} seconds"
}, {
  code: 47,
  name: "Group Max Current Threshold",
  params: [{
    label: "Group",
    unit: "",
    input: "select"
  }, {
    label: "Current RMS Threshold",
    unit: "amps",
    input: "number",
    placeholder: "",
    step: "0.01",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3"
  }],
  triggeredDescription: "Group {{param1}} has exceeded {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Group {{param1}} has not exceeded {{param2}} amps for {{param3}} seconds"
}, {
  code: 48,
  name: "Group Min Current Threshold",
  params: [{
    label: "Group",
    unit: "",
    input: "select"
  }, {
    label: "Current RMS Threshold",
    unit: "amps",
    input: "number",
    placeholder: "",
    step: "0.01",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3"
  }],
  triggeredDescription: "Group {{param1}} has fallen below {{param2}} amps for {{param3}} seconds",
  notTriggeredDescription: "Group {{param1}} has not fallen below {{param2}} amps for {{param3}} seconds"
}, {
  code: 49,
  name: "Temperature Max Threshold",
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    placeholder: ""
  },{
    label: "Temperature Threshold",
    unit: "celsius",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "-20",
    max: "100",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Sensor {{param1}} has exceeded {{param2}} degrees celsius for {{param3}} seconds",
  notTriggeredDescription: "Sensor {{param1}} has not exceeded {{param2}} degrees celsius for {{param3}} seconds"
}, {
  code: 50,
  name: "Temperature Min Threshold",
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    placeholder: ""
  },{
    label: "Temperature Threshold",
    unit: "celsius",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "-20",
    max: "100",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Sensor {{param1}} has fallen below {{param2}} degrees celsius for {{param3}} seconds",
  notTriggeredDescription: "Sensor {{param1}} has not fallen below {{param2}} degrees celsius for {{param3}} seconds"
}, {
  code: 51,
  name: "Humidity Max Threshold",
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    placeholder: ""
  },{
    label: "Humidity Threshold",
    unit: "relative humidity",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Sensor {{param1}} has exceeded {{param2}} relative humidity for {{param3}} seconds",
  notTriggeredDescription: "Sensor {{param1}} has not exceeded {{param2}} relative humidity for {{param3}} seconds"
}, {
  code: 52,
  name: "Humidity Min Threshold",
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    placeholder: ""
  },{
    label: "Humidity Threshold",
    unit: "relative humidity",
    input: "number",
    placeholder: "",
    step: "0.01",
    indicatorThreshold: true,
    min: "0",
    max: "100",
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3",
    min: "3",
    max: "86400"
  }],
  triggeredDescription: "Sensor {{param1}} has fallen below {{param2}} relative humidity for {{param3}} seconds",
  notTriggeredDescription: "Sensor {{param1}} has not fallen below {{param2}} relative humidity for {{param3}} seconds"
}, {
  code: 53,
  name: "Daily Time to Trigger",
  params: [{
    label: "Time To Trigger",
    unit: "",
    displayUnit: "(24-hour clock)",
    input: "time",
    placeholder: ""
  }],
  
}, {
  code: 54,
  name: "Time Interval Between Triggers",
  params: [{
    label: "Time Between Triggers",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 3000",
    min: "3",
    max: "86400"
  }],
},{
  code: 55,
  name: "ATS Switched to Secondary",
  params: []
},{
  code: 56,
  name: "ATS Switched to Primary",
  params: []
},{
  code: 57,
  name: "Secondary Power Source Lost",
  params: []
},{
  code: 58,
  name: "Secondary Power Source Restored",
  params: []
},{
  code: 59,
  name: "System Power Recovery Event",
  params: [{
    label: "Bank",
    unit: "",
    input: "select",
    indicatorThreshold: true,
  }],
},{
  code: 60,
  name: "Monthly Scheduled Event",
  helpSections: [{
    title: "Day of the month",
    desc: "Day of the month in which this event will be triggered. Valid values are 1-28."
  }, {
    title: "Hour of the day",
    desc: "Specifies the hour within day to trigger. Valid values are 0-23."
  }, {
    title: "Minute of the hour",
    desc: "Specifies the minute within the hour to trigger. Valid values are 0-59."
  }],
  params: [{
    label: "Day of the month",
    unit: "day of month", //ignored on scheduledtriggerlist
    displayUnit: "", //ignored on scheduledtriggerlist
    input: "number",
    placeholder: "eg. 12",
    min: "1",
    max: "28"
  },{
    label: "Hour of the day",
    unit: "hour",
    input: "number",
    placeholder: "eg. 1",
    min: "0",
    max: "23"
  },{
    label: "Minute of the hour",
    unit: "minute",
    input: "number",
    placeholder: "eg. 5",
    min: "0",
    max: "59"
  }],
},{
  code: 61,
  name: "Weekly Scheduled Event",
  helpSections: [{
    title: "Day of the week",
    desc: "Day of the week in which this event will be triggered. Valid values are Sunday-Saturday."
  }, {
    title: "Hour of the day",
    desc: "Specifies the hour within day to trigger. Valid values are 0-23."
  }, {
    title: "Minute of the hour",
    desc: "Specifies the minute within the hour to trigger. Valid values are 0-59."
  }],
  params: [{
    label: "Day of the week",
    unit: "day of the week", //ignored on scheduledtriggerlist
    displayUnit: "", //ignored on scheduledtriggerlist
    input: "select",
    options: [{
      value: "0",
      label: "Sunday"
    },{
      value: "1",
      label: "Monday"
    },{
      value: "2",
      label: "Tuesday"
    },{
      value: "3",
      label: "Wednesday"
    },{
      value: "4",
      label: "Thursday"
    },{
      value: "5",
      label: "Friday"
    },{
      value: "6",
      label: "Saturday"
    }],
  },{
    label: "Hour of the day",
    unit: "hour",
    input: "number",
    placeholder: "eg. 1",
    min: "0",
    max: "23"
  },{
    label: "Minute of the hour",
    unit: "minute",
    input: "number",
    placeholder: "eg. 5",
    min: "0",
    max: "59"
  }],
},{
  code: 62,
  name: "IO Hub Sensor Digital Input State High",
  helpSections: [{
    title: "Digital Input State High",
    desc: "This event triggers when the digital input is in the high state for the specified amount of time."
  }, {
    title: "Digital Input Index",
    desc: "Specifies the digital input to monitor. Valid values are 1-6 and match the panel labels for digital inputs."
  }],
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    // indicatorThreshold: true,
  }, {
    label: "IO Hub Sensor Digital Input Index",
    unit: "",
    input: "select",
    options: [{
      value: "1",
      label: "Digital Input 1"
    },{
      value: "2",
      label: "Digital Input 2"
    },{
      value: "3",
      label: "Digital Input 3"
    },{
      value: "4",
      label: "Digital Input 4"
    }, {
      value: "5",
      label: "Digital Input 5"
    }, {
      value: "6",
      label: "Digital Input 6"
    }],
    
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 30",
    min: "3",
    max: "86400"
  }],
}, {
  code: 63,
  name: "IO Hub Sensor Digital Input State Low",
  helpSections: [{
    title: "Digital Input State Low",
    desc: "This event triggers when the digital input is in the low state for the specified amount of time."
  }, {
    title: "Digital Input Index",
    desc: "Specifies the digital input to monitor. Valid values are 1-6 and match the panel labels for digital inputs."
  }],
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
    // indicatorThreshold: true,
  }, {
    label: "Digital Input Index",
    unit: "",
    input: "select",
    options: [{
      value: "1",
      label: "Digital Input 1"
    },{
      value: "2",
      label: "Digital Input 2"
    },{
      value: "3",
      label: "Digital Input 3"
    },{
      value: "4",
      label: "Digital Input 4"
    },{
      value: "5",
      label: "Digital Input 5"
    },{
      value: "6",
      label: "Digital Input 6"
    }],
  }, {
    label: "Max Time Over Threshold Before Action",
    unit: "Seconds",
    input: "number",
    placeholder: "eg. 30",
    min: "3",
    max: "86400"
  }],
}, {
  code: 64,
  name: "IO Hub Sensor User Switch Triggered",
  helpSections: [{
    title: "User Switch Triggered",
    desc: "This event triggers when the user switch is triggered on the front panel of a SPA-0140 SynLink Smart IO Hub."
  }],
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
  },{
    label: "State triggered to",
    unit: "",
    input: "select",
    options: [{
      value: "HIGH",
      label: "On"
    },{
      value: "LOW",
      label: "Off"
    }],
  }],
}, {
  code: 65,
  name: "Sensor Connected/Disconnected",
  params: [{
    label: "Sensor Port",
    unit: "",
    input: "select",
    options: [{
      value: "A",
      label: "Port A Sensor"
    },{
      value: "B",
      label: "Port B Sensor"
    }],
  }, {
    label: "Sensor Connection State",
    unit: "",
    input: "select",
    options: [{
      value: "connect",
      label: "Connected"
    },{
      value: "disconnect",
      label: "Disconnected"
    }],
  }],
}];



export default eventRefs;