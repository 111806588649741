import React from "react";

import DeviceAlerts from "./deviceAlerts";
import { Switch, Route } from "react-router-dom";

import SubHeader from "../../components/common/topHeader/subHeader";
import Loading from "../../components/common/loading";
// import CalenderInput from "../../components/common/inputs/calenderInput";
import DevicePageInlet from './sections/devicePageInlet';
import DevicePageOutlets from './sections/devicePageOutlet';
import DeviceDetails from './sections/deviceDetails';
import DevicePageOutletPage from './sections/devicePageOutletPage';
import DevicePageSensors from './sections/devicePageSensors';


export default class ConnectedDevicePage extends React.Component {
  componentDidMount() {

    this.props.getDeviceItemAndData(this.props.match.params.enclosureSerialNumber);
    this.props.fetchFwStatus(this.props.match.params.enclosureSerialNumber);
  }
  unpair = (cb = () => {}) => {
    this.props.removeResource(
      "devices",
      this.props.match.params.enclosureSerialNumber,
      (err, res) => {
        cb();
        this.props.history.replace("/devices/connected");
      },
      "enclosureSerialNumber"
    );
  };
  sections = () => {
    const foundDevice =
      this.props.resources.devices.find(
        (d) =>
          d.enclosureSerialNumber ==
          this.props.match.params.enclosureSerialNumber
      ) || {};

    return [
      {
        label: "Details",
        icon: "public",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/details`,
        component: () => (
          foundDevice ?
          <DeviceDetails
            device={foundDevice}
            {...this.props}
            unpair={this.unpair}
          />
          :
          null
        ),
      },
      {
        label: "Power Consumption",
        icon: "power",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/inlets`,
      props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            devices: this.props.resources.devices.filter(
              (d) =>
                d.enclosureSerialNumber ==
                this.props.match.params.enclosureSerialNumber
            ),
            inlets: (foundDevice.inlets || []).length > 0 ? foundDevice.inlets : this.props.resources.inlets.filter(
              (i) =>
                i.enclosureSerialNumber ==
                this.props.match.params.enclosureSerialNumber
            ),
            // inlets: this.props.resources.devices.find(d => d.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber)?.inlets || [],
            banks: this.props.resources.banks.filter(
              i => 
                i.enclosureSerialNumber ==
                this.props.match.params.enclosureSerialNumber
            )
          },
          hideGroupInputs: true,
        },
        component: DevicePageInlet,
        disabled: this.props.user.role && !this.props.user.role.viewInletInfo,
      },
      {
        label: "Manage Outlets",
        icon: "outlet",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/outlets`,
        props: {
          foundDevice,
          ...this.props,
          resources: {
            ...this.props.resources,
            outlets: (foundDevice.outlets || []).length > 0 ? foundDevice.outlets : this.props.resources.outlets.filter(
              (i) =>
                i.enclosureSerialNumber ==
                this.props.match.params.enclosureSerialNumber
            ),
            // outlets: this.props.resources.devices.find(d => d.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber)?.outlets || [],
          },
          hideGroupInputs: true,
        },
        exact: true,
        disabled: !(foundDevice.outletSwitchingSupported || foundDevice.outletPwrMeasurementsSupported) || (this.props.user.role && !this.props.user.role.viewOutletInfo),
        component: DevicePageOutlets,
      },
      {
        label: "Manage Outlets",
        icon: "outlet",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/outlets/:section_id`,
        props: {
          foundDevice,
          foundOutlet: this.props.resources.outlets.find(o => o.id == this.props.match.params.section_id),
          ...this.props,
          resources: {
            ...this.props.resources,
            outlets: this.props.resources.outlets.filter(
              (i) =>
                i.enclosureSerialNumber ==
                this.props.match.params.enclosureSerialNumber
            ),
          },
        },
        component: DevicePageOutletPage,
        unlisted: true
        // disabled: true,
      },
      {
        label: "Alerts",
        icon: "notifications",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/alerts`,
        disabled: this.props.user.role && !this.props.user.role.viewEventInfo,
        component: () => (
          <DeviceAlerts
            {...this.props}
            resources={{
              ...this.props.resources,
              events: this.props.resources.events.filter(
                (d) =>
                  d.enclosureSerialNumber ==
                  this.props.match.params.enclosureSerialNumber
              ),
            }}
          />
        ),
      }, {
        label: "Sensors",
        icon: "device_thermostat",
        path: `/devices/connected/${foundDevice.enclosureSerialNumber}/sensors`,
        disabled: this.props.user.role && !this.props.user.role.viewSensorInfo,
        component: () => (
          <DevicePageSensors
            {...this.props}
            resources={{
              ...this.props.resources,
              sensors: this.props.resources.sensors.filter(
                (d) =>

                  d.enclosureSerialNumber ==
                  this.props.match.params.enclosureSerialNumber
              ),
            }}
          />
        ),
      }
    ];
  };
  render() {
    const sections = this.sections();

    if (this.props.resources.devicesLoading) {
      return <Loading black />
    }
    const foundDevice = this.props.resources.devices.find(d => d.enclosureSerialNumber === this.props.match.params.enclosureSerialNumber);
    return (
      <div>
        <SubHeader
          sections={sections.filter(section => !section.disabled).filter(section => !section.unlisted)} 
        />
        <Switch>
          {sections &&
            sections.map((section) => {
              // if (section.disableAccess) return null;
              if (section.disabled) return null;
              
              return (
                <Route
                  key={section.label}
                  path={section.path}
                  exact={section.exact}
                  render={(props) => {
                    if (section.disabled) {
                      return null;
                    } 
                    // const CompToRender = scrollTopOnMount(section.component);
                    const CompToRender = section.component;
                    return <CompToRender 
                      {...(section.props || {})} 
                      match={this.props.match}
                    />;
                  }}
                />
              );
            })}
        </Switch>
      </div>
    );
  }
}

