import React from "react";
// import Loading from "../../components/common/loading";
// import icons from '../../../assets/icon-all';
import { Link } from "react-router-dom";
import ModalManager from "../../components/common/modal/ModalManager";
import TextInput from "../../components/common/inputs/textInput";
import Pagination from "../../components/common/pagination";
import axios from "axios";
import { formatDistance } from "date-fns";
import NotificationManager from '../../utils/notificationManager';

const columnWidths = [
  1, // 0
  3, // 1
  2, // 2
  5, // 3
  2, // 4
  2, // 5
  4, // 6
  3, // 7
  5, // 8
  2, // 9
  1, // 10
];

export default class DeviceFirmware extends React.Component {
  componentDidMount() {
  }
  state = {
    searchBar: "",
    currentFilter: "",
    connectionFilter: "",
    sortFilter: "",
  };
  updateDevice = (enclosureSerialNumber) => {
    this.props.getDeviceItemAndData(enclosureSerialNumber);
    this.props.fetchFwStatus(enclosureSerialNumber);
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar,
    });
  };
  render() {
    const {
      devices = [],
      devicesLoading,
      devicesLoaded,
      inletsLoading,
      outletsLoading,
      eventsLoading,
      conf = []
    } = this.props.resources;
    const { addGroupDeviceActive } = this.props;

    const { searchBar, currentFilter } = this.state;

    // const filteredDevices = devices.map(d => `${d.deviceName} ${d.enclosureSerialNumber} ${d.modelNumber}`).filter(d => d.toLowerCase().includes(searchBar.toLowerCase()));
    const filteredDevices = devices
      .filter((d) =>
        `${d.deviceName} ${d.enclosureSerialNumber} ${d.modelNumber}`
          .toLowerCase()
          .includes(currentFilter.toLowerCase())
      )
      .filter((d) => {
        if (this.state.connectionFilter === "") {
          return true;
        } else {
          return d.connectionState === this.state.connectionFilter;
        }
      });
    let sortedFilteredDevices;
    const [sortKey, sortDirection] = this.state.sortFilter.split("_");
    if (this.state.sortFilter) {
      sortedFilteredDevices = filteredDevices.sort((a, b) => {
        if (sortKey === "ipAddress") {
          const foundConfA = conf.find(
            (conf) => conf.enclosureSerialNumber == a.enclosureSerialNumber
          );
          const foundConfB = conf.find(
            (conf) => conf.enclosureSerialNumber == b.enclosureSerialNumber
          );
          let ipAddrA;
          let ipAddrB;
          if (foundConfA) {
            if (foundConfA.ipAssign === "dhcp" && foundConfA.dhcpAssignedIp) {
              ipAddrA = foundConfA.dhcpAssignedIp;
            } else if (
              foundConfA.ipAssign === "static" &&
              foundConfA.staticIp
            ) {
              ipAddrA = foundConfA.staticIp;
            }
          } else {
            ipAddrA = "-";
          }
          if (foundConfB) {
            if (foundConfB.ipAssign === "dhcp" && foundConfB.dhcpAssignedIp) {
              ipAddrB = foundConfB.dhcpAssignedIp;
            } else if (
              foundConfB.ipAssign === "static" &&
              foundConfB.staticIp
            ) {
              ipAddrB = foundConfB.staticIp;
            }
          } else {
            ipAddrB = "-";
          }
          if (sortDirection === "asc") {
            if (ipAddrA < ipAddrB) {
              return -1;
            }
            if (ipAddrA > ipAddrB) {
              return 1;
            }
            return 0;
          } else {
            if (ipAddrA > ipAddrB) {
              return -1;
            }
            if (ipAddrA < ipAddrB) {
              return 1;
            }
            return 0;
          }
        } else {
          if (sortDirection === "asc") {
            if (a[sortKey] < b[sortKey]) {
              return -1;
            }
            if (a[sortKey] > b[sortKey]) {
              return 1;
            }
            return 0;
          } else {
            if (a[sortKey] > b[sortKey]) {
              return -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return 1;
            }
            return 0;
          }
        }
      });
    } else {
      sortedFilteredDevices = filteredDevices;
    }
    // console.log(sortDirection)
    // console.log(sortDirection)
    const sortOptions = [
      { label: "Default", value: "" },
      // { label: `Device Name ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "deviceName_desc" : "deviceName_asc" },
      // { label: `Serial Number ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "enclosureSerialNumber_asc" : "enclosureSerialNumber_desc" },
      // { label: `IP Address ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "ipAddress_asc" : "ipAddress_desc" },
      { label: "Device Name A-Z", value: "deviceName_asc" },
      { label: "Device Name Z-A", value: "deviceName_desc" },
      { label: "Serial Number ↑", value: "enclosureSerialNumber_asc" },
      { label: "Serial Number ↓", value: "enclosureSerialNumber_desc" },
      { label: "IP Address ↑", value: "ipAddress_asc" },
      { label: "IP Address ↓", value: "ipAddress_desc" },
    ];

    return (
      <div style={{ marginTop: "2vh", overflowX: "auto" }}>
        <div className="grid-x ">
          <div className="cell large-6 medium-6 small-12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Search for model number, device name, or serial number"
                value={this.state.searchBar}
                onChange={(e) => {
                  this.setState({ searchBar: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.applyFilters();
                  }
                }}
              />
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: "0.45rem",
                  height: "2.43rem",
                  margin: "0 0 1rem",
                  borderRadius: "0.1875rem",
                  // backgroundColor: "#49535E",
                  // color: "#fff",
                }}
                className="background-color-secondary"
                onClick={() => {
                  this.applyFilters();
                }}
              >
                <i className="material-icons" style={{ marginRight: "0px" }}>
                  search
                </i>
              </span>
            </div>
          </div>
          {addGroupDeviceActive && this.props.match.params.groupId && (
            <div
              className="cell large-6 medium-6 small-12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to={`/devices/groups/${this.props.match.params.groupId}/devices/edit`}
                style={{ color: "white" }}
              >
                <div className="button">Manage group devices</div>
              </Link>
            </div>
          )}
        </div>
        <div className="grid-x">
          <div className="cell large-6">
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "20px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6b6b6b",
                    marginBottom: "5px",
                    marginLeft: "3px",
                  }}
                >
                  Filter by
                </div>
                <select
                  style={{
                    margin: "0px",
                  }}
                  onChange={(e) => {
                    this.setState({
                      connectionFilter: e.target.value,
                    });
                  }}
                >
                  <option value="">All Status</option>
                  <option label="Connected">Connected</option>
                  <option label="Disconnected">Disconnected</option>
                </select>
              </span>
              <span>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6b6b6b",
                    marginBottom: "5px",
                    marginLeft: "3px",
                  }}
                >
                  Sort by
                </div>
                <select
                  onChange={(e) => {
                    this.setState({
                      sortFilter: e.target.value,
                    });
                  }}
                >
                  {sortOptions.map((option, i) => {
                    return (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </span>
            </div>
          </div>
          <div className="cell large-6">
            <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          </div>
        </div>

        <div className={`grid-row header`}>
          <div style={{ flex: columnWidths[0] }}></div>
          <div style={{ flex: columnWidths[1] }}>Model Number</div>
          <div style={{ flex: columnWidths[2] }}>Serial number</div>
          <div style={{ flex: columnWidths[3] }}>Device Name</div>
          <div style={{ flex: columnWidths[4] }}>IP Address</div>
          <div style={{ flex: columnWidths[5] }}>Firmware</div>
          <div style={{ flex: columnWidths[6] }}>Firmware Update</div>
          {/* <div style={{ flex: columnWidths[9] }}>Last Update</div> */}
          <div style={{ flex: columnWidths[10] }}></div>
        </div>

        {devicesLoading && (
          <div style={{}}>
            {[null, null, null, null].map((item, index) => {
              return (
                <div className="grid-row-wrapper" key={index}>
                  <div className="grid-row">
                    <div style={{ flex: columnWidths[0] }}></div>
                    <div style={{ flex: columnWidths[1] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[2] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[3] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[4] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[5] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[6] }}><div className="skeleton-blank" /></div>
                    <div style={{ flex: columnWidths[10] }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!devicesLoading && devices.length === 0 && (
          <React.Fragment>
            <div className="grid-row-wrapper">
              <div className="grid-row">
                <div
                  style={{
                    paddingLeft: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div>No Devices Found</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
              }}
            >
              <span className="button">
                <a
                  href="https://synaccess.readme.io/reference/connect-a-synlink-pdu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Learn how to connect a SynLink PDU
                </a>
              </span>
            </div>
          </React.Fragment>
        )}
        <Pagination
          items={sortedFilteredDevices}
          key={JSON.stringify(
            sortedFilteredDevices.map((d) => d.enclosureSerialNumber)
          )}
          renderItem={(device, i) => {
            // return DeviceLineItem(device,i, this.props, this.updateDevice);
            return (
              <DeviceLineItem
                device={device}
                i={i}
                {...this.props}
                updateDevice={this.updateDevice}
              />
            );
          }}
        />
      </div>
    );
  }
}

class DeviceLineItem extends React.Component {
  componentDidMount = async () => {
    const { fetchFwStatus } = this.props;
    if (fetchFwStatus) {
      await fetchFwStatus(this.props.device.enclosureSerialNumber);
    }
  };

  componentDidUpdate = async (
    prevProps,
    prevState,
    snapshot
  ) => {
    // check that the line item has changed enclosure serial numbers
    if (
      this.props.device.enclosureSerialNumber !==
      prevProps.device.enclosureSerialNumber
    ) {
      const { fetchFwStatus } = this.props;
      if (fetchFwStatus) {
        await fetchFwStatus(this.props.device.enclosureSerialNumber);
      }
    }
  }

  issueFirmwareUpdate = async (device, fwVersion) => {
    ModalManager.confirm(
        "Are you sure you want to update the firmware? Please do not power off the device during a firmware update. The device may be less responsive during a firmware update. ",
        "Update Firmware",
        "Update",
        async (status, close) => {
            if (status) {
                try {
                    const response = await axios.post(
                      `/v1/devices/${this.props.device?.enclosureSerialNumber}/fwUpdate`,
                      { firmwareVersion: fwVersion },
                      {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      }
                    );
              
                    // If successful (200)
                    if (response?.status === 200) {
                        NotificationManager.success(`Firmware update initialized successfully`)
                        close()
                        await this.props.fetchFwStatus(this.props.device.enclosureSerialNumber);
                        // set a timeout to run this fetchFwStatus after 3 minutes, 6 minutes then 11 minutes
                        setTimeout(async () => {
                            await this.props.fetchFwStatus(this.props.device.enclosureSerialNumber);
                        }
                        , 3 * 60 * 1000);
                        setTimeout(async () => {
                            await this.props.fetchFwStatus(this.props.device.enclosureSerialNumber);
                        }
                        , 6 * 60 * 1000);
                        setTimeout(async () => {

                            await this.props.fetchFwStatus(this.props.device.enclosureSerialNumber);
                        }
                        , 11 * 60 * 1000);

                    } else {
                      // Not a 200 => handle as error
                      // In practice, you might show a notification or set state for error

                    }
                  } catch (error) {
                    console.error(error);

                  } finally {

                  }
            }
        }
    )
  }

  render() {
    const { device, i, updateDevice } = this.props;
    const fwStatuses = this.props.resources.fwStatus;
    const foundfwStatus = fwStatuses.find(
      (fw) => fw.enclosureSerialNumber == device.enclosureSerialNumber
    );
    const currentFwVersion = foundfwStatus?.fwStatus?.currentFwVersion || "";
    const latestFwVersion = foundfwStatus?.fwStatus?.latestFwVersion || "";
    const secondsSinceInitiated = foundfwStatus?.fwStatus?.secondsSinceInitiated || "";
    const updatePendingFirmware = foundfwStatus?.fwStatus?.updatePendingFirmware || "";
    const updateStatus = foundfwStatus?.fwStatus?.updateStatus || "";
    const availableUpdate = foundfwStatus?.fwStatus?.availableUpdate || "";
    const fwStatusLoading = foundfwStatus?.loading;
    const fwStatusLoaded = foundfwStatus?.loaded;
    
    
    const foundConf = this.props.resources.conf.find(
      (conf) => conf.enclosureSerialNumber == device.enclosureSerialNumber
    );
    let ipAddr;
    if (foundConf) {
      if (foundConf.ipAssign === "dhcp" && foundConf.dhcpAssignedIp) {
        ipAddr = foundConf.dhcpAssignedIp;
      } else if (foundConf.ipAssign === "static" && foundConf.staticIp) {
        ipAddr = foundConf.staticIp;
      }
    } else {
      ipAddr = "-";
    }


    const connectionEstablished = device.connectionState === "Connected";
    return (
      <div
        key={i}
        className={`grid-row-wrapper ${
          device.connectionState === "Connected" ? "" : "disconnected"
        }`}
        title={device.connectionState === "Connected" ? "" : "Disconnected"}
      >
        <div
          className={`grid-row ${
            connectionEstablished ? "connected" : "disconnected"
          }`}
        >
          <div
            style={{
              fontWeight: "600",
              flex: columnWidths[0],
              color: connectionEstablished ? "forestgreen" : "maroon",
            }}
            // className={connectionEstablished ? 'green' : 'red'}
            title={
              connectionEstablished
                ? "Connection Established"
                : "Connection Cannot Be Made"
            }
          >
            <i className="material-icons" style={{ marginLeft: "4px" }}>
              {connectionEstablished ? "cloud_done" : "cloud_off"}
            </i>
          </div>
          <div
            style={{
              flex: columnWidths[1],
            }}
          >
            <Link to={`/devices/connected/${device.enclosureSerialNumber}`}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  <b>
                    {device.modelNumber
                      ? device.modelNumber.startsWith("NP")
                        ? device.modelNumber
                        : `SP-${device.modelNumber}`
                      : "-"}
                  </b>
                </span>
              </span>
            </Link>
          </div>

          <div
            style={{
              // color: "rgba(0,0,0,0.7)",
              flex: columnWidths[2],
              // display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'flex-start'
            }}
          >
            {device.enclosureSerialNumber}
          </div>
          <div
            style={{
              flex: columnWidths[3],
            }}
          >
            {device.deviceName}
          </div>

          <div
            style={{
              flex: columnWidths[4],
              // fontWeight: "600",
            }}
          >
            {this.props.resources.confLoading ? (
              <div className="skeleton-blank" />
            ) : (
              <span>{ipAddr}</span>
            )}
          </div>
          <div
            style={{
              flex: columnWidths[5],
              textTransform: "capitalize",
              // fontWeight: "600",
              // color: "rgba(0,0,0,0.7)",
              // display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'flex-start'
              // justifyContent: 'center'
            }}
          >
            {(fwStatusLoading && !fwStatusLoaded) ? (
                <div className="skeleton-blank" />
            ) : (
                <div>
                    {currentFwVersion} {fwStatusLoading && <Loading black />}
                </div>
            )}
          </div>
          <div
            style={{
              flex: columnWidths[6],
            }}
          >
            {(fwStatusLoading && !fwStatusLoaded) ? (
                <div className="skeleton-blank" />
            ) : (
                updateStatus === "inProgress" ? (
                    <span>
                        <span
                            style={{
                                // color: "orange"
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            Updating ({updatePendingFirmware}) <Loading black />
                        </span>
                    </span>
                ) : (
                    (updateStatus === "failed" && secondsSinceInitiated < (30*60)) ? (
                        <span>
                            <span
                                style={{
                                    color: "maroon",
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                Failed ({updatePendingFirmware}) 
                                {/* time ago */}
                                <span
                                    style={{
                                        fontSize: "90%",
                                        marginLeft: "4px"
                                    }}
                                >
                                    {secondsSinceInitiated ? `${Math.floor(secondsSinceInitiated/60)} minutes ago` : ""}
                                </span>                                    
                            </span>
                        </span>
                    ) : (
                        <span
                            style={{
                                color: (!availableUpdate && (latestFwVersion && currentFwVersion) && (latestFwVersion === currentFwVersion)) ? "forestgreen" : "inherit"
                            }}
                        >
                            {availableUpdate ?
                                <span
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    title="Click to update firmware"
                                    onClick={() => {
                                        this.issueFirmwareUpdate(device, latestFwVersion);
                                    }}
                                >
                                    <span
                                        style={{
                                            textDecoration: "underline",

                                        }}
                                    >
                                        Available ({latestFwVersion})
                                    </span>
                                    <i className="material-icons" style={{ marginLeft: "4px", textDecoration: "none", fontSize: "150%" }}>
                                        update
                                    </i>
                                </span>:
                                <span>
                                    {(latestFwVersion && currentFwVersion) && (latestFwVersion == currentFwVersion) ? ("Up to date") : ("Not Available")}
                                </span>
                            }
                        </span>
                    )

                )
                
            )}
          </div>
          {/* <div style={{ flex: columnWidths[9] }}>
            <span
              style={{
                fontSize: "90%",
              }}
            >
              {device.lastUpdated &&
                `${formatDistance(device.lastUpdated, new Date())} ago`}
            </span>
          </div> */}
          <div style={{ flex: columnWidths[10] }}>
            <span
              title="Refresh PDU Information"
              className="black"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                updateDevice(device.enclosureSerialNumber);
              }}
            >
              <i
                style={{ fontWeight: "600", color: "#6c6f72" }}
                className="material-icons"
              >
                sync
              </i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}


const Loading = ({
    size,
    black,
    fixed,
  }) => (
    <span id={fixed ? "wrapper-fixed" : "wrapper"}>
      <span className="profile-main-loader">
        <div
          className="loader"
          style={{
            width: size || "20px",
            height: size || "20px",
            margin: "0px"
          }}
        >
          <svg className="circular-loader" viewBox="25 25 50 50" >
            <circle
              className={black ? "loader-path-black" : "loader-path"}
              cx="50" cy="50" r="20" 
              fill="none" 
              strokeWidth="2"
            />
          </svg>
        </div>
      </span>
    </span>
  )