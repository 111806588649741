import React from 'react';
// import refs from '../../../assets/refs'
import refs from '../../assets/refs'
const eventCategories = refs.eventCategories;

const data = [
    { "C": "11", "N": "Network Device Detection", "P1": "Target IP Address", "P2": "# successful pings before action", "P3": "--", "D": "IP Address to Ping. Successful pings creates trigger." },
    { "C": "12", "N": "AutoPing Timeout", "P1": "Target IP Address", "P2": "# fails before action", "P3": "--", "D": "IP Address to Ping. Failure to ping creates trigger." },
  { "C": "13", "N": "Inlet Line Max Current Threshold", "P1": "Inlet Line", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to 3 phase PDUs with line measurements. Amperage threshold in amps" },
  { "C": "14", "N": "Inlet Line Min Current Threshold", "P1": "Inlet Line", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to 3 phase PDUs with line measurements. Amperage threshold in amps" },
  { "C": "15", "N": "Inlet Max Current Threshold", "P1": "Inlet ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Amperage threshold in amps" },
  { "C": "16", "N": "Inlet Min Current Threshold", "P1": "Inlet ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Amperage threshold in amps" },
  { "C": "17", "N": "Inlet Max Voltage Threshold", "P1": "Inlet ID", "P2": "Voltage Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Voltage threshold in volts" },
  { "C": "18", "N": "Inlet Min Voltage Threshold", "P1": "Inlet ID", "P2": "Voltage Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Voltage threshold in volts" },
  { "C": "19", "N": "Inlet Max Line Frequency Threshold", "P1": "Inlet ID", "P2": "Line Frequency Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Line frequency threshold in hertz" },
  { "C": "20", "N": "Inlet Max Line Frequency Threshold", "P1": "Inlet ID", "P2": "Line Frequency Threshold", "P3": "# secs past threshold", "D": "Applies to single phase PDUs. Line frequency threshold in hertz" },
  { "C": "21", "N": "Inlet Min Power Factor", "P1": "Inlet ID", "P2": "Power Factor Threshold", "P3": "# secs past threshold", "D": "Power Factor Threshold as a ratio" },
  { "C": "22", "N": "Inlet Active Energy Accumulation Max", "P1": "Inlet ID", "P2": "kWh Threshold", "P3": "--", "D": "--" },
  { "C": "24", "N": "Inlet Min Active Power Threshold", "P1": "Inlet ID", "P2": "Active Power Threshold", "P3": "# secs past threshold", "D": "Active Power Threshold in watts" },
  { "C": "23", "N": "Inlet Max Active Power Threshold", "P1": "Inlet ID", "P2": "Active Power Threshold", "P3": "# secs past threshold", "D": "Active Power Threshold in watts" },
  { "C": "25", "N": "Inlet Max Apparent Power Threshold", "P1": "Inlet ID", "P2": "Apparent Power Threshold", "P3": "# secs past threshold", "D": "Apparent Power Threshold in volt-amperes (VA)" },
  { "C": "26", "N": "Inlet Min Apparent Power Threshold", "P1": "Inlet ID", "P2": "Apparent Power Threshold", "P3": "# secs past threshold", "D": "Apparent Power Threshold in volt-amperes (VA)" },
  { "C": "29", "N": "Inlet 3 Phase Imbalance", "P1": "Inlet ID", "P2": "% Imbalance Threshold", "P3": "# secs past threshold", "D": "--" },
  { "C": "30", "N": "Bank Circuit Breaker Trip", "P1": "Bank ID", "P2": "--", "P3": "--", "D": "--" },
  { "C": "31", "N": "Bank Max Current Threshold", "P1": "Bank ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Amperage threshold in amps" },
  { "C": "32", "N": "Bank Min Current Threshold", "P1": "Bank ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Amperage threshold in amps" },
  { "C": "33", "N": "Bank Max Voltage Threshold", "P1": "Bank ID", "P2": "Voltage Threshold", "P3": "# secs past threshold", "D": "Voltage threshold in volts" },
  { "C": "34", "N": "Bank Min Voltage Threshold", "P1": "Bank ID", "P2": "Voltage Threshold", "P3": "# secs past threshold", "D": "Voltage threshold in volts" },
  { "C": "35", "N": "Bank Max Line Frequency Threshold", "P1": "Bank ID", "P2": "Line Frequency Threshold", "P3": "# secs past threshold", "D": "Line Frequency threshold in hertz" },
  { "C": "36", "N": "Bank Min Line Frequency Threshold", "P1": "Bank ID", "P2": "Line Frequency Threshold", "P3": "# secs past threshold", "D": "Line Frequency threshold in hertz" },
  { "C": "37", "N": "Bank Min Power Factor", "P1": "Bank ID", "P2": "Power Factor Threshold", "P3": "# secs past threshold", "D": "--" },
  { "C": "38", "N": "Bank Active Energy Accumulation Max", "P1": "Bank ID", "P2": "kWh Threshold", "P3": "--", "D": "--" },
  { "C": "39", "N": "Bank Max Active Power Threshold", "P1": "Bank ID", "P2": "Active Power Threshold", "P3": "# secs past threshold", "D": "Active Power Threshold in watts" },
  { "C": "40", "N": "Bank Min Active Power Threshold", "P1": "Bank ID", "P2": "Active Power Threshold", "P3": "# secs past threshold", "D": "Active Power Threshold in watts" },
  { "C": "41", "N": "Bank Max Apparent Power Threshold", "P1": "Bank ID", "P2": "Apparent Power Threshold", "P3": "# secs past threshold", "D": "Apparent Power Threshold in volt-amperes (VA)" },
  { "C": "42", "N": "Bank Min Apparent Power Threshold", "P1": "Bank ID", "P2": "Apparent Power Threshold", "P3": "# secs past threshold", "D": "Apparent Power Threshold in volt-amperes (VA)" },
  { "C": "45", "N": "Outlet Max Current Threshold", "P1": "Outlet ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to PDUs with Outlet Current Measurements. Amperage threshold in amps" },
  { "C": "46", "N": "Group Max Current Threshold", "P1": "Group ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to PDUs with Outlet Current Measurements. Amperage threshold in amps" },
  { "C": "47", "N": "Group Min Current Threshold", "P1": "Group ID", "P2": "Amperage Threshold", "P3": "# secs past threshold", "D": "Applies to PDUs with Outlet Current Measurements. Amperage threshold in amps" },
  { "C": "49", "N": "Temperature Max Threshold", "P1": "Sensor ID", "P2": "Temperature Threshold", "P3": "# secs past threshold", "D": "Requires attached temperature/humidity sensor. Temperature threshold in celsius" },
  { "C": "50", "N": "Temperature Min Threshold", "P1": "Sensor ID", "P2": "Temperature Threshold", "P3": "# secs past threshold", "D": "Requires attached temperature/humidity sensor. Temperature threshold in celsius" },
  { "C": "51", "N": "Humidity Max Threshold", "P1": "Sensor ID", "P2": "Humidity Threshold", "P3": "# secs past threshold", "D": "Requires attached temperature/humidity sensor. Humidity threshold as relative humidity %" },
  { "C": "52", "N": "Humidity Min Threshold", "P1": "Sensor ID", "P2": "Humidity Threshold", "P3": "# secs past threshold", "D": "Requires attached temperature/humidity sensor. Humidity threshold as relative humidity %" },
  { "C": "53", "N": "Scheduled Daily Time", "P1": "Time to trigger", "P2": "--", "P3": "--", "D": "Time of day in military time. example: 13:01" },
  { "C": "54", "N": "Scheduled Time Interval", "P1": "--", "P2": "--", "P3": "--", "D": "Triggers every X seconds forever" },
  { "C": "55", "N": "ATS Switched to Secondary", "P1": "--", "P2": "--", "P3": "--", "D": "Applies to ATS PDUs. Triggers when Primary ATS Inlet has lost power and switches to Secondary" },
  { "C": "56", "N": "ATS Switched to Primary", "P1": "--", "P2": "--", "P3": "--", "D": "Applies to ATS PDUs. Triggers when Primary ATS Inlet has recovered and switches from Secondary to Primary" },
  { "C": "57", "N": "Secondary Power Source Lost", "P1": "--", "P2": "--", "P3": "--", "D": "Applies to ATS PDUs. Triggers when Secondary ATS Inlet has lost power." },
  { "C": "58", "N": "Secondary Power Source Restored", "P1": "--", "P2": "--", "P3": "--", "D": "Applies to ATS PDUs. Triggers when Secondary ATS Inlet has recovered power." },
  { "C": "59", "N": "System Power Recovery Event", "P1": "Bank ID", "P2": "--", "P3": "--", "D": "Fires when the given Bank has booted up after having no power. Will fire with circuit breaker trip for supported devices. For single inlet devices, choose any bank. For Dual circuit devices, Banks are linked with inlets." },
  { "C": "60", "N": "Monthly Scheduled Event", "P1": "Day of Month", "P2": "Hour Of Day", "P3": "Minute of Hour", "D": "Day of month as a number between 1 and 28, hour of day as 0-23, minute as 0-59" },
  { "C": "61", "N": "Weekly Scheduled Event", "P1": "Day of Week", "P2": "Hour of Day", "P3": "Minute of hour", "D": "Day of week as 0-6, hour of day as 0-23, minute at 0-59" },
  { "C": "62", "N": "Digital Input State High", "P1": "Sensor Port (A or B)", "P2": "Digital Input Index (1-6)", "P3": "# secs past threshold", "D": "Requires SPA-0140 Smart IO Hub. Triggers an event when a hub's digital input senses high for # secs past threshold" },
  { "C": "63", "N": "Digital Input State Low", "P1": "Sensor Port (A or B)", "P2": "Digital Input Index (1-6)", "P3": "# secs past threshold", "D": "Requires SPA-0140 Smart IO Hub. Triggers an event when a hub's digital input senses low for # secs past threshold" },
  { "C": "64", "N": "User Switch Triggered", "P1": "Sensor Port (A or B)", "P2": "State triggered to", "P3": "--", "D": "Requires SPA-0140 Smart IO Hub. If state is triggered OFF, then when user switch is turned off, event is triggered" },
  { "C": "65", "N": "Sensor Connected/Disconnected", "P1": "Sensor Port (A or B)", "P2": "Connect/Disconnect", "P3": "--", "D": "" }
]
const tableStyle = {
  // maxWidth: '800px',
  // margin: '0 auto',
  backgroundColor: "white",
  marginBottom: "30px"
};

var thStyle = {
  // backgroundColor: '#f2f2f2',
  // color: 'white',
  padding: '8px',
  // border: '1px solid #ddd',
  // borderRadius: "2px",
  fontWeight: "400",
  textAlign: 'start',
  border: "solid rgba(0,0,0,0.08) 1px"
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'flex-start',
};

const tdStyle = {
  padding: '8px',
  border: '1px solid #ddd',
};
const altRowStyle = {
  backgroundColor: '#f7f7f7', // Slightly grey background color
};

const categoriesWithHelp = eventCategories.map(category => {
  return ({
    label: category.label,
    data: category.codes.map(code => {
      return data.find(event => event.C === code.toString());
    }).filter(event => event !== undefined)
  })
})
export default class EventActions extends React.Component {

  render() {
    return (
      <div>
        <h3 style={{ marginTop: "20px" }}>Alerting Event Documentation</h3>
      <p style={{ marginBottom: "30px", paddingBottom: "15px", borderBottom: "solid rgba(0,0,0,0.2) 1px"}}>Event triggers are conditions that are monitored by the PDU. When the condition is met, the PDU will trigger an event action. The following tables help explain what the different triggers are and how to use them.</p>
        {categoriesWithHelp.map((category, index) => {
          return (
            <div key={category.label}>
              <h5>{category.label}</h5>
              <div style={tableStyle}>
                <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
                  <thead>
                    <tr>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Code</th>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Name</th>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Parameter 1</th>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Parameter 2</th>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Parameter 3</th>
                      <th style={{...thStyle }} className="app-item background-color-quinary">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.data.map((row, index) => (
                      <tr key={row.C} style={index % 2 === 1 ? altRowStyle : {}}>
                        <td style={{ ...tdStyle, ...{ backgroundColor: index % 2 === 1 ? '#f7f7f7' : 'inherit' } }}>{row.C}</td>
                        <td style={tdStyle}>{row.N}</td>
                        <td style={tdStyle}>{row.P1}</td>
                        <td style={tdStyle}>{row.P2}</td>
                        <td style={tdStyle}>{row.P3}</td>
                        <td style={tdStyle}>{row.D}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
          )
        })}
      
      </div>


    )
  }
}



