import React from "react";
import { parseISO, format, formatDistanceToNow } from "date-fns";

import ActivityLog from "../../logging/activityLog";
import axios from "axios";
import RowList from "../../../components/common/rowList";
import ModalManager from "../../../components/common/modal/ModalManager";
import TextInput from "../../../components/common/inputs/textInput";
// import { formatDuration, intervalToDuration } from "date-fns";
import Loading from "../../../components/common/loading";

// function humanDuration(time) {
//   if (!time) return null;
//   return formatDuration(intervalToDuration({ start: 0, end: time }));
// }
export default class DeviceDetails extends React.Component {
  state = {
    activityLoading: false,
    granularity: null,
    loading: false,

    deviceName: "",
    deviceNameLoading: false,
  };
  componentDidMount = async () => {
    this.setState({
      deviceName: this.props.device.deviceName,
    });

    if (
      !this.props.resources.activityLoaded &&
      !this.props.resources.activityLoading
    ) {
      if (this.props.resources.activityLoaded.length > 0) {
      } else {
        this.setState({
          activityLoading: true,
        });
        this.props.fetchResource("activity", (err, data) => {
          // console.log(err, data)
          this.setState({
            activityLoading: false,
          });
          if (data) {
            // console.log(data.data)
          }
        });
      }
    }

    this.setState({
      granularity: this.props.device.cloudPostIntervalMs / 1000,
    });
  };

  onUnpairButtonPress = () => {
    ModalManager.confirm(
      "This action will unpair the PDU from your account. You can pair the PDU again by logging in directly to the PDU.",
      "Unpair PDU",
      "Confirm",
      (submitted, close) => {
        if (submitted) {
          this.props.unpair(() => {
            close();
          });
        } else {
          // close
        }
      }
    );
  };

  onSaveClick = () => {
    this.setState({ loading: true });
    this.props.modifyResource(
      "devices",
      this.props.device.enclosureSerialNumber,
      {
        // cloudPostIntervalMs: this.state.granularity * 1000,
        deviceName: this.state.deviceName,
      },
      () => {
        this.setState({ loading: false });
      },
      "enclosureSerialNumber"
    );
  };

  render() {
    const { device } = this.props;
    const isOnline = device.connectionState === "Connected";

    const outlets = this.props.resources.outlets.filter(
      (o) => o.enclosureSerialNumber === device.enclosureSerialNumber
    );
    const hasEdit = this.props.device.deviceName !== this.state.deviceName;
    const foundConf = this.props.resources.conf.find(
      (conf) => conf.enclosureSerialNumber == device.enclosureSerialNumber
    );
    let ipAddr;
    if (foundConf) {
      if (foundConf.ipAssign === "dhcp" && foundConf.dhcpAssignedIp) {
        ipAddr = foundConf.dhcpAssignedIp;
      } else if (foundConf.ipAssign === "static" && foundConf.staticIp) {
        ipAddr = foundConf.staticIp;
      }
    } else {
      ipAddr = "-";
    }


    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell large-6 medium-6">
            <div style={{}}>
              {/* <h4
                  style={{
                    // marginBottom: "20px",
                  }}
                >
                  Device Info
                </h4> */}
              {/* 
              {!(
                this.props.user.role && !this.props.user.role.editDeviceInfo
              ) && ( */}
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                </div> */}
                <div
                  style={
                    {
                      // backgroundColor: "white",
                      // padding: "20px",
                      // marginBottom: "100px",
                      // boxShadow: "3px 3px 10px rgb(0 0 0 / 10%)",
                      // borderTop: "solid 3px #ddd",
                      // marginTop: "30px",
                    }
                  }
                >
                  <div
                    style={
                      {
                        // paddingTop: "20px",
                      }
                    }
                  >
                    <TextInput
                      label="Device Name"
                      value={this.state.deviceName}
                      callback={(txt) => {
                        this.setState({ deviceName: txt });
                      }}
                      placeholder="Enter Device Name"
                      readOnly={!isOnline}
                    />
                  </div>

                  {hasEdit && (
                    <div className="button" onClick={() => this.onSaveClick()}>
                      {this.state.loading ? <Loading /> : "Save"}
                    </div>
                  )}
                </div>
              </div>
              {/* )} */}
              <RowList
                rows={[
                  // {
                  //   label: "Device Information",
                  //   labelStyle: {
                  //     fontWeight: "bold",
                  //     fontSize: "110%",
                  //   },
                  // },
                  {
                    label: "Name",
                    value: device.deviceName,
                    valueStyle: {
                      // fontWeight: "600",
                    },
                  },
                  {
                    label: "Model Number",
                    value: device.modelNumber,
                    valueStyle: {
                      // fontWeight: "500",
                    },
                  },
                  {
                    label: "Enclosure Serial Number",
                    value: device.enclosureSerialNumber,
                  },
                  {
                    label: "Outlet Switching",
                    value: device.outletSwitchingSupported
                      ? "Supported"
                      : "Not Supported",
                  },
                  {
                    label: "Outlet Power Measurements",
                    value: device.outletPwrMeasurementsSupported
                      ? "Supported"
                      : "Not Supported",
                  },
                  {
                    label: "Number of Banks",
                    value: device.numBanks,
                  },
                  {
                    label: "Number of Outlets",
                    value: device.numOutlets,
                  },
                  {
                    label: "Number of Relay Outputs",
                    value: device.numDryContactRelays,
                    disabled: device.numDryContactRelays === 0,
                  },
                  // {
                  //   label: "Controller Serial Number",
                  //   value: device.controllerSerialNumber,
                  // },
                  {
                    label: "Inlet Configuration",
                    value:
                      device.inletConfig === "standard"
                        ? "Standard"
                        : device.inletConfig === "ats"
                        ? "Automatic Transfer Switch (ATS)"
                        : device.inletConfig === "dualcircuit"
                        ? "Dual Circuit"
                        : "-",
                  },
                  {
                    label: "Form Factor",
                    value: device.formFactor,
                  },
                  {
                    label: "Phase",
                    value: device.phase,
                  },
                  {
                    label: "IP Address",
                    value: ipAddr,
                    disabled: !ipAddr
                  }
                ]}
              />
              <FwUpdateStatus {...this.props} />
              <div
                style={{
                  paddingTop: "30px",
                }}
              >
                <div>Unnpair PDU from Synaccess Cloud Account</div>
                <div
                  className="button"
                  style={{
                    backgroundColor: "maroon",
                    marginTop: "10px",
                  }}
                  onClick={() => this.onUnpairButtonPress()}
                >
                  Unpair Now
                </div>
              </div>
            </div>
          </div>
          {!(this.props.user.role && !this.props.user.role.viewActivityLog) && (
            <div className="cell large-6 medium-6">
              <ActivityLog
                outlets={outlets}
                devices={[device]}
                selectedDevice={device}
                activity={this.props.resources.activity.filter(
                  (a) =>
                    a.resourceId == device.enclosureSerialNumber ||
                    a.enclosureSn == device.enclosureSerialNumber
                )}
                refresh={() => {
                  this.setState({
                    activityLoading: true,
                  });
                  this.props.fetchResource("activity", (err, data) => {
                    this.setState({
                      activityLoading: false,
                    });
                  });
                }}
                loading={this.state.activityLoading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

class FwUpdateStatus extends React.Component {
  state = {
    loading: false, // For indicating "Firmware Update" button press in progress
    polling: false, // Track whether we have an ongoing polling interval
    error: null, // For any error messages (TBD how you show them)
  };
  pollingInterval = null;

  // sometimes the fwStatus will be loaded on component did mount. and if so, check if the status is inProgress and if so then start polling
  // if the fwStatus is not loaded, then use componentDidUpdate to check if the status is inProgress and if so then start polling
  componentDidMount = () => {
    const { fwStatus, fwStatusLoaded } = this.props.resources;
    if (fwStatusLoaded && fwStatus?.updateStatus === "inProgress") {
      this.startPolling();
    }
  };
  componentDidUpdate = (prevProps) => {
    const { fwStatus, fwStatusLoaded } = this.props.resources;
    if (
      fwStatusLoaded &&
      fwStatus?.updateStatus === "inProgress" &&
      !this.state.polling
    ) {
      this.startPolling();
    }
  };
  componentWillUnmount = () => {
    this.stopPolling();
  };
  startPolling = () => {
    if (this.state.polling) {
      return;
    }
    this.setState({
      polling: true,
    });
    this.props.fetchFwStatus(this.props.device?.enclosureSerialNumber);
    this.pollingInterval = setInterval(() => {
      this.props.fetchFwStatus(this.props.device?.enclosureSerialNumber);
    }, 12000);
  };
  stopPolling = () => {
    this.setState({
      polling: false,
    });
    clearInterval(this.pollingInterval);
  };

  initiateFirmwareUpdate = async (fwVersion) => {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      const response = await axios.post(
        `/v1/devices/${this.props.device?.enclosureSerialNumber}/fwUpdate`,
        { firmwareVersion: fwVersion },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      // If successful (200)
      if (response?.status === 200) {
        // start polling
        this.startPolling();
      } else {
        // Not a 200 => handle as error
        // In practice, you might show a notification or set state for error
        this.setState({
          error: "Unable to initiate firmware update (non-200).",
        });
      }
    } catch (error) {
      console.error(error);
      const errMessage = error?.response?.data?.message
      this.setState({
        error: errMessage || "Unable to initiate firmware update. Check console for details.",
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    // const { fwStatus, fwStatusLoading, fwStatusLoaded } = this.props.resources;
    const fwStatuses = this.props.resources.fwStatus;
    const foundFwStatus = fwStatuses?.find(
      (f) => f.enclosureSerialNumber == this.props.device?.enclosureSerialNumber
    );
    const fwStatus = foundFwStatus?.fwStatus;
    const fwStatusLoading = foundFwStatus?.loading;
    const fwStatusLoaded = foundFwStatus?.loaded;
    const lastUpdated = foundFwStatus?.lastUpdated;
    const { loading, error } = this.state;

    // Extract interesting fields from fwStatus
    const currentFwVersion = fwStatus?.currentFwVersion;
    const latestFwVersion = fwStatus?.latestFwVersion;
    const availableUpdate = fwStatus?.availableUpdate;
    const updateStatus = fwStatus?.updateStatus; // "inProgress", "success", "failed", or ""
    const updatePendingFirmware = fwStatus?.updatePendingFirmware;
    const updateInitiatedTime = fwStatus?.updateInitiatedTime;
    const secondsSinceInitiated = fwStatus?.secondsSinceInitiated;
    return (
      <div
        style={{
          marginTop: "30px",
          marginBottom: "20px",
          backgroundColor: "",
          // padding: "10px 20px",
          // boxShadow: "3px 3px 10px rgb(0 0 0 / 10%)",
        }}
      >
        <h5 style={{ marginBottom: "10px" }}>Firmware Update</h5>

        {/* If resource call is loading, show spinner */}
        {fwStatusLoading && !fwStatusLoaded && (
          <div>
            <Loading black />
          </div>
        )}

        {/* Show any error messages (TBD how you want to style/handle them) */}
        {error && <div style={{ color: "maroon", marginLeft: "5px", marginBottom: "20px" }}>{error}</div>}

        {/* If we have fwStatus data */}
        {fwStatus && (
          <>
            <RowList
              rows={[
                {
                  label: "Current Firmware",
                  value: (
                    <div style={{ display: 'flex'}}>
                      {currentFwVersion} {currentFwVersion === latestFwVersion && "(Up to date)"}
                      {fwStatusLoading ? (
                        <Loading black />
                      ) : (
                        <span
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            this.props.fetchFwStatus(
                              this.props.device?.enclosureSerialNumber
                            )
                          }
                        >
                          <i className="material-icons">refresh</i>
                        </span>

                      )}
                    </div>
                  ),
                },
                {
                  label: "Available Firmware Update",
                  value: (
                    <div>
                      <button
                        className="button"
                        style={{
                          margin: "0px"
                        }}
                        onClick={() =>
                          this.initiateFirmwareUpdate(latestFwVersion)
                        }
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Firmware Update Device"}
                      </button>
                    </div>
                  ),
                  disabled: !(availableUpdate && updateStatus != "inProgress"),
                },
              ]}
            />
            {/* <div>Current Firmware</div>
            <div style={{ fontWeight: "bold" }}>
              {currentFwVersion}{" "}
              {currentFwVersion === latestFwVersion && "(Up to date)"}
            </div> */}

            {/* {availableUpdate && updateStatus != "inProgress" && (
              <div>
                <div>Available Firmware Update</div>
                <div style={{ fontWeight: "bold" }}>{latestFwVersion}</div>

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <button
                    className="button"
                    onClick={() => this.initiateFirmwareUpdate(latestFwVersion)}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Firmware Update Device"}
                  </button>
                </div>
              </div>
            )} */}
            {/* No update available */}
            {/* {!availableUpdate && fwStatusLoaded && (
              <div style={{ margin: "10px 10px 20px 0px" }}>
                <em>No firmware update available</em>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Last Checked:{" "}
              {lastUpdated ? new Date(lastUpdated).toLocaleString() : "N/A"}
              {fwStatusLoading ? (
                <span style={{ marginLeft: "10px" }}>
                  <Loading black />
                </span>
              ) : (
                <span
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.props.fetchFwStatus(
                      this.props.device?.enclosureSerialNumber
                    )
                  }
                >
                  <i className="material-icons">refresh</i>
                </span>
              )}
            </div> */}

            <div
              style={{
                // borderTop: "solid rgba(0,0,0,0.2) 1px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
            {updateInitiatedTime &&
            <div
              style={{
                backgroundColor: "rgb(236 239 243)",
                padding: "15px",
                border: "solid #ccc 1px",
                borderRadius: "3px",
                fontSize: "90%",
              }}
            >
              {updateInitiatedTime && updateStatus === "inProgress" && (
                <div>
                  <div style={{ marginBottom: "", fontSize: "110%" }}>
                    Update History
                  </div>
                  <div
                    style={{
                      color: "#777",
                      fontSize: "91%",
                    }}
                  >
                    {format(new Date(updateInitiatedTime), "MMM d, HH:mm ")} -
                    Initiated{" "}
                    {formatDistanceToNow(new Date(updateInitiatedTime))} ago
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    Firmware update to version {updatePendingFirmware} in progress...
                  </div>
                </div>
              )}

              {updateInitiatedTime &&
                (updateStatus == "success" || updateStatus === "failed") && (
                  <div>
                    <div style={{ fontSize: "110%" }}>Update History</div>
                    <div
                      style={{
                        color: "#777",
                        fontSize: "91%",
                      }}
                    >
                      {format(new Date(updateInitiatedTime), "MMM d, HH:mm ")} -
                      Initiated{" "}
                      {formatDistanceToNow(new Date(updateInitiatedTime))} ago
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {secondsSinceInitiated > (30*60) ? ( // older than 30 minutes dont state the success/failure
                      <>
                      Firmware update was initiated on {" "}
                      {format(new Date(updateInitiatedTime), "MMM d, HH:mm ")}{" "}
                      to version {updatePendingFirmware}
                      
                      </>  
                    ) : (
                        <>

                        Firmware update was issued{" "}
                        {updateStatus === "success"
                          ? "successfully"
                          : updateStatus === "failed"
                          ? "unsuccessfully"
                          : ""}{" "}
                        on{" "}
                        {format(new Date(updateInitiatedTime), "MMM d, HH:mm ")}{" "}
                        to version {updatePendingFirmware}
                        </>

                      )}
                    </div>
                  </div>
                )}
            </div>

            }
          </>
        )}
      </div>
    );
  }
}
