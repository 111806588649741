 import DeviceOutlets from './devices/deviceOutlets';
import DeviceInlets from './devices/deviceInlets';
import DeviceAlerts from './devices/deviceAlerts';
import DeviceConnected from './devices/deviceConnected';
import DeviceSensors from './devices/deviceSensors';
import DeviceGroups from './devices/deviceGroups'
import DeviceFirmware from './devices/deviceFirmware';

import DeviceGroupPage from './devices/deviceGroupPage'
import EditDeviceGroupPage from './devices/addDeviceGroupPage'

import LoggingUserLogs from './logging/userLogs';
import LoggedInUser from './users/loggedInUser';
import UsersBilling from './users/usersBilling';
import UserSAML from './users/samlAuthentication'
import UserTeamMembers from './users/userTeamMembers'
import UserRoles from './users/userRoles'
import UserRolePage from './users/userRolePage'
import TeamSettings from './users/teamSettings';
import UserTeamMemberItem from './users/userTeamMemberItem.jsx';
import AlertDocumentation from './help/alertDocumentation';

import {Redirect} from 'react-router-dom'

import Loading from '../components/common/loading';
import ConnectedDevicePage from './devices/deviceConnectedPage';
import DeviceAlertItemPage from './devices/deviceAlertsItemPage'
import UserApiAccess from './users/apiAccess';

const unlistedPages = () => [
  {
    basePath: "/devices/outlets/:outletId",
    component: ({ 
      resources: {
        outlets, outletsLoading
      }, 
      match 
    }) => {
      if (outletsLoading) {
        return <Loading black />
      } else {
        const outlet = outlets.find(o => o.id === match.params.outletId)
        if (!outlet) {
          return "Cannot find outlet"
        } else {
          return <Redirect to={`/devices/connected/${outlet.enclosureSerialNumber}/outlets/${match.params.outletId}`}/>
        }
      }

    }
  },
  {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber",
    component: props => <Redirect to={`/devices/connected/${props.match.params.enclosureSerialNumber}/details`} />,
    headerIcon: "power"
  }, {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/alerts/:eventId",
    headerIcon: "notifications",
    component: DeviceAlertItemPage
  }, {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/:section",
    component: ConnectedDevicePage,
    headerIcon: "power"
  },{
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/:section/:section_id",
    component: ConnectedDevicePage,
    headerIcon: "power"
  }, {
    headerTitle: "Manage Group Devices",
    basePath: "/devices/groups/:groupId/devices/edit",
    component: EditDeviceGroupPage,
    headerIcon: "group"
  }, {
    headerTitle: "Device Group",
    basePath: ["/devices/groups/:groupId", "/devices/groups/:groupId/:section"],
    component: DeviceGroupPage,
    headerIcon: "group"
  }, {
    headerTitle: "Manage Role",
    basePath: "/users/roles/:roleId",
    component: UserRolePage,
    headerIcon: "security"

  }, {
    headerTitle: "Manage Team Member",
    basePath: "/team/members/:userId",
    headerIcon: "supervisor_account",
    component: UserTeamMemberItem
  }, {
    headerTitle: "Manage Team Member",
    basePath: "/team/members/:userId/:section",
    headerIcon: "supervisor_account",
    component: UserTeamMemberItem
  }, {
    headerTitle: "Alert Documentation",
    basePath: "/alerts-documentation",
    headerIcon: "help",
    component: AlertDocumentation
  }
]

const pages = (
  user = {},
  resources = {},
) => {
  const isAdmin =
  user.has_admin_privileges ||
  user.is_enterprise_admin;
const combinedGroupRole = (resources.groups || []).map(g => g.role).filter(g => g)
.reduce((sum, item) => {
  Object.keys(item).forEach(key => {
    if (typeof item[key] === 'boolean') {
      sum[key] = sum[key] || item[key];
    }
  });
  return sum;
}, {});

  return [
...unlistedPages().map(p => ({
  ...p,
  unlisted: true,
  exact: true
})), {
  headerTitle: "Devices",
  headerIcon: "cloud_queue",
  basePath: "/devices",
  defaultPath: "/devices/connected",
  sections: [{
    label: "Connected",
    icon: "public",
    path: "/devices/connected",
    component: DeviceConnected,
    // disabled: user.role && !user.role.viewDeviceInfo
  }, {
    label: "Device Groups",
    icon: "group",
    path: "/devices/groups",
    component: DeviceGroups,
    disabled: user.enterprise_Id && (resources.groups || []).length === 0

  }, {
    label: "Power Consumption",
    icon: "power",
    path: "/devices/inlets",
    component: DeviceInlets,
    disabled: (user.role && !user.role.viewInletInfo) && (combinedGroupRole && !combinedGroupRole.viewInletInfo)
  }, {
    label: "Manage Outlets",
    icon: "outlet",
    path: "/devices/outlets",
    component: DeviceOutlets,
    disabled: (user.role && !user.role.viewAllOutletsAllowed) && (combinedGroupRole && !combinedGroupRole.viewAllOutletsAllowed)
  // }, {
  //   label: "Banks",
  //   icon: "settings_input_component",
  //   path: "/devices/banks",
  //   component: DeviceBanks,
  }, {
    label: "Sensors",
    icon: "device_thermostat",
    path: "/devices/sensors",
    component: DeviceSensors,
    disabled: (user.role && !user.role.viewSensorInfo) && (combinedGroupRole && !combinedGroupRole.viewSensorInfo)
  }, {
    label: "Alerts",
    icon: "notifications",
    path: "/devices/alerts",
    component: DeviceAlerts,
    disabled: (user.role && !user.role.viewEventInfo) && (combinedGroupRole && !combinedGroupRole.viewEventInfo)
  }, {
    label: "Firmware",
    icon: "update",
    path: "/devices/firmware",
    component: DeviceFirmware,
    disabled: !isAdmin
  }]
}, {
  headerTitle: "Activity",
  headerIcon: 'date_range',
  defaultPath: "/activity/history",
  basePath: "/activity",
  disabled: (user.role && !user.role.viewActivityLog) && (combinedGroupRole && !combinedGroupRole.viewActivityLog),
  sections: [{
  //   label: "Notifications",
  //   icon: "notifications",
  //   path: "/activity/notifications",
  //   component: LoggingEventLogs
  // }, {
    label: "History",
    icon: "history",
    path: "/activity/history",
    component: LoggingUserLogs
  }]
}, {
  headerTitle: "API",
  headerIcon: "code",
  basePath: "/api",
  defaultPath: "/api/api-access",
  sections: [{
    label: "Access",
    icon: "key",
    path: "/api/api-access",
    component: UserApiAccess,
  }]
}, {
  headerTitle: "Team",
  headerIcon: "group",
  basePath: "/team",
  defaultPath: "/team/members",
  disabled: !(user.has_admin_privileges || user.is_enterprise_admin),
  sections: [{
    label: "Manage Members",
    path: "/team/members",
    icon: "supervisor_account",
    component: UserTeamMembers,
    // disabled: !(user.has_admin_privileges || user.is_enterprise_admin)
  }, {
    label: "Team Settings",
    icon: "settings",
    path: "/team/settings",
    component: TeamSettings,
  }, {
    label: "SAML Auth",
    icon: "vpn_key",
    path: "/team/saml",
    component: UserSAML,
    // disabled: !(user.has_admin_privileges || user.is_enterprise_admin) 

    // disabled: !user.enterprise
  }, {
    label: "Roles",
    icon: "security",
    path: "/team/roles",
    component: UserRoles,
  }],
  }, {
    headerTitle: "Profile",
    headerIcon: "account_circle",
    basePath: "/users",
    defaultPath: "/users/logged-in",
    hideFromHeader: true,
    sections: [{
      label: "Profile",
      icon: "settings",
      path: "/users/logged-in",
      component: LoggedInUser,
    },{
      label: "Billing",
      icon: "credit_card",
      path: "/users/billing",
      component: UsersBilling,
      disabled: (() => {
        if (user.enterprise_Id) {
          if (user.is_enterprise_admin) {
            return false
          }
        }  else {
          return false
        }
        return true
      })()
      // disabled: (!user.is_enterprise_admin || !user.enterprise)
    }]


}]
}



export default pages;