import React from "react";

/* Pagination Component 
-------------------------------------------------*/

// const propTypes = {
//   items: React.PropTypes.array.isRequired,
//   onChangePage: React.PropTypes.func.isRequired,
//   initialPage: React.PropTypes.number
// }

const defaultProps = {
  initialPage: 1,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.items.length !== prevProps.items.length) {
      this.setPage(this.props.initialPage);
    } else {
      const paginationIdArray = this.props.items.map(i => i.paginationId);
      const prevPaginationIdArray = prevProps.items.map(i => i.paginationId);
      if ((paginationIdArray.length === 0) || (prevPaginationIdArray.length === 0)) {
        // skip unused pagination
      } else if (this.props.items !== prevProps.items) {
          // Calculate the total pages for the *new* items
          const newTotalPages = Math.ceil(this.props.items.length / this.state.pager.pageSize);

          // Keep the current page, but clamp it if it’s now too large
          let currentPage = this.state.pager.currentPage;
          if (currentPage > newTotalPages) {
            currentPage = newTotalPages || 1;
          }
        this.setPage(currentPage)
      }  else {
        if (paginationIdArray.join('') !== prevPaginationIdArray.join('')) {
          this.setPage(this.props.initialPage)
        }
      }
    }
  }

  setPage(page) {
    var items = this.props.items;
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page);

    // get new page of items from items array
    var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <ul className="pagination">
        <li
          className={pager.currentPage === 1 ? "disabled" : ""}
          style={{
            listStyle: "none",
          }}
        >
          <span onClick={() => this.setPage(1)}>First</span>
        </li>
        <li className={pager.currentPage === 1 ? "disabled" : ""}>
          <span onClick={() => this.setPage(pager.currentPage - 1)}>Previous</span>
        </li>
        {pager.pages.map((page, index) => (
          <li
            key={index}
            className={pager.currentPage === page ? "active" : ""}
          >
            <span onClick={() => this.setPage(page)}>{page}</span>
          </li>
        ))}
        <li
          className={pager.currentPage === pager.totalPages ? "disabled" : ""}
        >
          <span onClick={() => this.setPage(pager.currentPage + 1)}>Next</span>
        </li>
        <li
          className={pager.currentPage === pager.totalPages ? "disabled" : ""}
        >
          <span onClick={() => this.setPage(pager.totalPages)}>Last</span>
        </li>
      </ul>
    );
  }
}

// Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

/* App Component 
-------------------------------------------------*/

class PaginationContainer extends React.Component {
  // constructor() {
  //     super();

  //     // an example array of items to be paged
  //     var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

  //     this.state = {
  //         exampleItems: exampleItems,
  //         pageOfItems: []
  //     };

  //     // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
  //     this.onChangePage = this.onChangePage.bind(this);
  // }
  state = {
    pageOfItems: [],
  };

  componentDidMount() {}

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  };

  render() {
    return (
      <div>
        {this.state.pageOfItems.map(this.props.renderItem)}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            items={this.props.items}
            onChangePage={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}

export default PaginationContainer;
