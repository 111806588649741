
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import Pagination from '../../components/common/pagination';
import Loading from '../../components/common/loading'
import React from 'react';
function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
const activityIcons = {
  user_edited: { icon: "manage_accounts", url: "/users/logged-in", },
  pat_created: { icon: "key", url: "", },
  pat_deleted: { icon: "delete", url: "", },
  guest_user_created: { icon: "group_add", url: "", },
  guest_user_modified: { icon: "edit", url: "", },
  guest_user_deleted: { icon: "delete", url: "", },

  device_paired: { icon: "login", url: "/devices/connected/:resourceId", },
  device_unpaired: { icon: "logout", url: "/devices/connected/:resourceId", },
  device_disconnected: { icon: "cloud_off", url: "/devices/connected/:resourceId", },
  device_connected: { icon: "cloud_done", url: "/devices/connected/:resourceId", },
  device_edited: { icon: "edit", url: "/devices/connected/:resourceId", }, // change name
  device_fw_update: { icon: "update", url: "/devices/connected/:resourceId", },
  
  outlet_switched: { icon: "outlet", url: "/devices/outlets/:resourceId", },
  outlet_edited: { icon: "edit", url: "/devices/outlets/:resourceId", },

  event_triggered: { icon: "notification", url: "", },
  event_created: { icon: "event_upcoming", url: "", },
  event_edited: { icon: "edit_calendar", url: "", },

  group_created: { icon: "ballot", url: "", },
  group_edited: { icon: "edit", url: "", },
  group_deleted: { icon: "delete", url: "", },


}
export default class ActivityLog extends React.Component {
  state = {
    activityFilter: "",
    resourceId: "",
    enclosureSN: "",
  }
  componentDidMount() {
    // console.log(window.location.search.replace("?", ""))
    const queryParameters = parseQuery(window.location.search);
    if (queryParameters.action) {
      this.setState({
        activityFilter: queryParameters.action
      })
    }
    if (queryParameters.enclosureSN) {
      this.setState({
        enclosureSN: queryParameters.enclosureSN
      })
    }
  }
  render() {
    const {
      activity,
      refresh,
      title,
    } = this.props;
    const actionFilteredActivity = this.state.activityFilter ?
      activity.filter(activity => this.state.activityFilter.includes(activity.action)) : activity

    const enclosureFilteredActivity = this.state.enclosureSN ?
      actionFilteredActivity.filter(activity => (activity.resourceId === this.state.enclosureSN) || (this.state.enclosureSN === activity.enclosureSn)) : actionFilteredActivity;

    const filteredActivity = this.state.resourceId ?
      enclosureFilteredActivity.filter(activity => this.state.resourceId == activity.resourceId) : enclosureFilteredActivity

    return (
      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.3)",
          // boxShadow: "3px 3px 3px rgba(0,0,0,0.1)",
          // borderRadius: "3px",
          // border: "solid #ddd 1px",
          padding: "15px",
          // paddingTop: "4rem",
          paddingBottom: "2rem"
        }}
      >
        {title &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: "1rem"
            }}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "110%",
              }}
            >
              {title}
            </span>
          </div>
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: "1rem"
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: "flex-start"
            }}
          >
            <div
              style={{
                paddingLeft: "10px"
              }}
            >
              <span 
                style={{ 
                  fontSize: "85%", 
                  color: "#666", 
                  // marginLeft: "10px" 
                }}
              >
                Filter by activity
              </span>
              <select
                onChange={e => {
                  console.log(e.target.value)
                  this.setState({
                    activityFilter: e.target.value,
                    // enclosureSN: ""
                    resourceId: e.target.value !== "outlet_switched,outlet_edited" ? "" : this.state.resourceId 
                  })
                }}
                value={this.state.activityFilter}
                // style={{
                //   // margin: "0px",
                //   // width: "fit-content",
                //   // border: "none",
                //   // backgroundColor: "transparent",
                //   // boxShadow: "none"
                // }}
                
                style={{
                  // marginLeft: "10px",
                }}
              >
                <option value="">All Activity</option>
                <option value="device_paired,device_unpaired">Device Pairing Activity</option>
                <option value="device_disconnected,device_connected">Device Connection Activity</option>
                <option value="device_edited">Device Editing Activity</option>
                <option value="outlet_switched,outlet_edited">Outlet Switching/Editing Activity</option>
                {!this.props.selectedDevice && 
                  <option value="group_created,group_edited,group_deleted">Outlet Group Activity</option>
                }
              </select>
            </div>
            {this.props.selectedDevice ? (
              null
            ) : (
              this.props.devicesLoading ? (
              <div
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "5px"
                }}
              >
                <Loading black />
              </div>
            ) : (
              this.state.activityFilter !== "group_created,group_edited,group_deleted"  &&
              <div
                style={{
                  paddingLeft: "10px"
                }}
              >
                <span style={{ fontSize: "85%", color: "#666" }}>Filter by device</span>
                <select
                  style={{
                    // marginLeft: "10px",
                  }}
                  value={this.state.enclosureSN}
                  onChange={e => this.setState({ enclosureSN: e.target.value })}
                >
                  <option value="">Select a Device</option>
                  {this.props.devices.map(device => {
                    return (
                      <option
                        key={device.enclosureSerialNumber}
                        value={device.enclosureSerialNumber}
                      >
                        {device.deviceName || `PDU SN: ${device.enclosureSerialNumber}`}
                      </option>
                    )
                  })}
                </select>
              </div>

            ))}
            {this.state.activityFilter === "outlet_switched,outlet_edited" &&
              <div
                  style={{
                    paddingLeft: "10px"
                  }}
              >
                <span style={{ fontSize: "85%", color: "#666" }}>Filter by Outlet</span>

                <select
                  onChange={e => {
                    this.setState({
                      resourceId: e.target.value
                    })
                  }}
                  style={{
                    // margin: "0px",
                    // width: "fit-content",
                    // border: "none",
                    // backgroundColor: "transparent",
                    // boxShadow: "none"
                    // marginLeft: "15px"
                  }}
                >
                  <option value="">Select an Outlet</option>
                  {this.props.outlets.map(outlet => {
                    return (
                      <option
                        key={outlet.id}
                        value={outlet.id}
                      >
                        {outlet.outletName || outlet.id}
                      </option>
                    )
                  })}
                </select>
              </div>

            }
          </div>
          <div>
            {refresh &&
              <span onClick={() => { refresh() }} style={{ cursor: 'pointer' }} >
                <i className="material-icons" style={{ fontSize: "30px" }}>refresh</i>
              </span>
            }
          </div>
        </div>

        {filteredActivity.length > 0 ? (
          <Pagination
            items={filteredActivity.map(activity => ({
              ...activity,
              paginationId: activity.createdAt
            }))}
            renderItem={(history) => {
              const timestamp = parseISO(history.createdAt);

              return (
                <div
                  style={{
                  }}
                  key={history.createdAt}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <span
                      style={{
                        // border: "solid #999 1px",
                        // borderRadius: "100%"

                      }}
                    >
                      <i
                        className="material-icons"
                        style={{
                          fontSize: "150%",
                          color: "#1d262f"
                        }}
                      >
                        {activityIcons[history.action] ? activityIcons[history.action].icon : "edit"}
                      </i>
                    </span>
                    <div>
                      <div>
                        {history.description}
                        {(activityIcons[history.action] && activityIcons[history.action].url) ? (
                          <React.Fragment>
                            <span style={{ paddingRight: "3px" }}>:</span>
                            <span style={{ textDecoration: "underline" }}>
                              <Link to={activityIcons[history.action].url.replace(":resourceId", history.resourceId)}>
                                {history.resourceName}
                              </Link>
                            </span>
                          </React.Fragment>
                        ) : (
                          `: ${history.resourceName}`
                        )}
                        {!history.details && history.counter > 1 && ` ${history.counter} times within 5 minutes`}
                      </div>
                      <div>
                        <div
                          style={{
                            color: "#777",
                            fontSize: "91%"
                          }}
                        >
                          {format(timestamp, "MMM d, HH:mm ")} - {formatDistanceToNow(timestamp)} ago
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: "solid #ddd 2px",
                      minHeight: '30px',
                      marginLeft: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      paddingLeft: "20px",
                      paddingTop: "10px",
                      paddingBottom: "10px"
                    }}
                  >
                    {history.details &&
                      <div
                        style={{
                          backgroundColor: "rgb(236 239 243)",
                          padding: "15px",
                          border: "solid #ccc 1px",
                          borderRadius: "3px",
                          fontSize: "90%"
                        }}
                      >
                        {history.details} {history.counter > 1 && `${history.counter} times within 5 minutes`}
                      </div>
                    }
                  </div>
                </div>
              )

            }}
          />
        ) : (
          this.props.loading ? (
            <Loading black />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              No Activity Yet
            </div>
          )
        )}
      </div>
    )
  }
}